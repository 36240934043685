import { Injectable } from '@angular/core';
import { decodeJwtPayload, NbAuthToken, NbTokenService } from '@nebular/auth';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

@Injectable()
export class RoleService {
  public roles$: Observable<string[]>;

  constructor(private readonly tokenService: NbTokenService) {
    this.roles$ = tokenService.tokenChange().pipe(
      map((x: NbAuthToken) => x.getPayload()),
      map((x: any) => (x ? decodeJwtPayload(x) : { roles: [] })),
      map((token: any) => token.roles),
      map((roles: string | string[]) => (Array.isArray(roles) ? roles : [roles])),
      shareReplay()
    );
  }
}
