import { Injectable } from '@angular/core';
import { ActivatedRoute, Data } from '@angular/router';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

@Injectable()
export class RouterDataService {
  constructor(private readonly activatedRoute: ActivatedRoute) {}

  public routerData$(activatedRoute: ActivatedRoute = this.activatedRoute): Observable<Data> {
    if (activatedRoute.children.length === 0) {
      return activatedRoute.data;
    }
    return activatedRoute.data.pipe(
      switchMap((data: Data) =>
        this.routerData$(activatedRoute.children[0]).pipe(
          map((childData: Data) => ({
            ...data,
            ...childData,
          }))
        )
      )
    );
  }
}
