import { ChangeDetectorRef, Component, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { NbAuthService, NbLogoutComponent, NB_AUTH_OPTIONS } from '@nebular/auth';

@Component({
  selector: 'adm-logout',
  templateUrl: './logout.component.html',
})
export class LogoutComponent extends NbLogoutComponent {
  constructor(
    protected service: NbAuthService,
    @Inject(NB_AUTH_OPTIONS) protected options: object = {},
    protected cd: ChangeDetectorRef,
    protected router: Router
  ) {
    super(service, options, router);
    localStorage.removeItem('auth_app_token');
    window.location.href = '/auth/login';
  }
}
