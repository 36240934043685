import { Component, Inject, Input, ViewChild } from '@angular/core';
import { ControlValueAccessor, DefaultValueAccessor, NgControl } from '@angular/forms';
import { EntityProperty, FILTER_COLUMN_DATA } from 'src/app/shared/utils/filter-column-data';

@Component({
  selector: 'adm-string-filter',
  templateUrl: './string-filter.component.html',
  styleUrls: ['./string-filter.component.scss'],
})
export class StringFilterComponent implements ControlValueAccessor {
  @Input() public placeholder: string;
  @ViewChild(DefaultValueAccessor, { static: true }) public valueAccessor: DefaultValueAccessor;

  constructor(control: NgControl, @Inject(FILTER_COLUMN_DATA) columnData: EntityProperty) {
    control.valueAccessor = this;
    this.placeholder = columnData.title;
  }

  public writeValue(value: any): void {
    this.valueAccessor.writeValue(value);
  }

  public registerOnChange(fn: any): void {
    this.valueAccessor.registerOnChange(fn);
  }

  public registerOnTouched(fn: any): void {
    this.valueAccessor.registerOnTouched(fn);
  }

  public setDisabledState(isDisabled: boolean): void {
    this.valueAccessor.setDisabledState(isDisabled);
  }
}
