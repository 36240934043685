import { Component } from '@angular/core';

@Component({
  selector: 'adm-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  public title = 'admin';
}
