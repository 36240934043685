import { Component, Inject, Input, ViewChild } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { NbSelectComponent } from '@nebular/theme';
import { EntityProperty, FILTER_COLUMN_DATA } from 'src/app/shared/utils/filter-column-data';

@Component({
  selector: 'adm-boolean-filter',
  templateUrl: './boolean-filter.component.html',
  styleUrls: ['./boolean-filter.component.scss'],
})
export class BooleanFilterComponent implements ControlValueAccessor {
  @Input() public placeholder: string;
  @ViewChild(NbSelectComponent, { static: true }) public valueAccessor: ControlValueAccessor;

  constructor(control: NgControl, @Inject(FILTER_COLUMN_DATA) columnData: EntityProperty) {
    control.valueAccessor = this;
    this.placeholder = columnData.title;
  }

  public writeValue(value: any): void {
    this.valueAccessor.writeValue(value);
  }

  public registerOnChange(fn: any): void {
    this.valueAccessor.registerOnChange(fn);
  }

  public registerOnTouched(fn: any): void {
    this.valueAccessor.registerOnTouched(fn);
  }

  public setDisabledState(isDisabled: boolean): void {
    this.valueAccessor.setDisabledState(isDisabled);
  }
}
