import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthModule } from './auth/auth.module';
import { PagesModule } from './pages/pages.module';
import { AuthGuard } from './shared/guards/auth.guard';

const routes: Routes = [
  {
    path: 'pages',
    canActivate: [AuthGuard],
    // tslint:disable-next-line: typedef
    loadChildren: (): Promise<PagesModule> => import('./pages/pages.module').then((m) => m.PagesModule),
  },
  {
    path: 'auth',
    // tslint:disable-next-line: typedef
    loadChildren: (): Promise<AuthModule> => import('./auth/auth.module').then((m) => m.AuthModule),
  },
  { path: '', redirectTo: 'pages/tags', pathMatch: 'full' },
  { path: '**', redirectTo: 'pages/tags' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
