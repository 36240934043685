import { Inject, Injectable } from '@angular/core';
import { NbAuthService, NbAuthStrategy, NbPasswordAuthStrategy, NbTokenService, NB_AUTH_STRATEGIES } from '@nebular/auth';
import { CONFIG_TOKEN } from 'config-token';
import { Config } from 'src/app/config/config.interface';

@Injectable()
export class AuthService extends NbAuthService {
  constructor(
    protected tokenService: NbTokenService,
    @Inject(NB_AUTH_STRATEGIES) protected strategies: NbAuthStrategy[],
    @Inject(CONFIG_TOKEN) protected config: Config
  ) {
    super(tokenService, strategies);

    const authStrategy = this.getStrategy('email') as NbPasswordAuthStrategy;
    authStrategy.setOptions({
      name: 'email',
      baseEndpoint: `${this.config.backendUrl}/admin`,
      token: {
        key: 'accessToken',
      },
      login: {
        endpoint: '/auth/login',
        method: 'post',
      },
      refreshToken: {
        endpoint: '/auth/refresh',
      },
      logout: {
        endpoint: '/auth/logout',
        method: 'post',
      },
    });
  }
}
