import { HttpClient, HttpClientModule, HttpRequest, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Injector, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BASE_PATH, Configuration } from '@hip/admin-api';
import {
  NbAuthJWTInterceptor,
  NbAuthJWTToken,
  NbAuthModule,
  NbAuthService,
  NbPasswordAuthStrategy,
  NB_AUTH_TOKEN_INTERCEPTOR_FILTER,
} from '@nebular/auth';
import { NbEvaIconsModule } from '@nebular/eva-icons';
import {
  NbDatepickerModule,
  NbDialogModule,
  NbIconModule,
  NbLayoutModule,
  NbMenuModule,
  NbSidebarModule,
  NbThemeModule,
  NbTimepickerModule,
  NbToastrModule,
} from '@nebular/theme';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { CONFIG_TOKEN } from 'config-token';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthModule } from './auth/auth.module';
import { AuthService } from './auth/services/auth.service';
import { Config } from './config/config.interface';
import { SharedModule } from './shared/shared.module';

// tslint:disable-next-line: only-arrow-functions
export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, '/assets/i18n/', '.json');
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    NgbModule,
    NbThemeModule.forRoot({ name: 'default' }),
    NbLayoutModule,
    NbEvaIconsModule,
    NbIconModule,
    NbDatepickerModule.forRoot(),
    NbTimepickerModule.forRoot(),
    NbAuthModule.forRoot({
      strategies: [
        // NbDummyAuthStrategy.setup({
        //   delay: 1500,
        //   name: 'email',
        //   token: {
        //     class: NbAuthJWTToken
        //   }
        // }),
        NbPasswordAuthStrategy.setup({
          name: 'email',
          token: {
            class: NbAuthJWTToken,
          },
        }),
      ],
      forms: {},
    }),
    AuthModule.forRoot(),
    NbSidebarModule.forRoot(),
    NbMenuModule.forRoot(),
    NbToastrModule.forRoot(),
    NbDialogModule.forRoot(),
    NbDatepickerModule.forRoot(),
    SharedModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
      defaultLanguage: 'en',
    }),
  ],
  providers: [
    { provide: NbAuthService, useClass: AuthService },
    {
      provide: Configuration,
      useFactory: (injector: Injector) => {
        const config = injector.get<Config>(CONFIG_TOKEN as any);
        return new Configuration({
          basePath: config.backendUrl,
          accessToken: JSON.parse(localStorage.getItem('auth_app_token'))?.value ?? '',
        });
      },
      deps: [Injector],
    },
    {
      provide: BASE_PATH,
      useFactory: (injector: Injector): string => {
        const config = injector.get<Config>(CONFIG_TOKEN as any);
        return config.backendUrl;
      },
      deps: [Injector],
    },
    {
      provide: 'MOCK_AUTH',
      useFactory: (injector: Injector): boolean => {
        const config = injector.get<Config>(CONFIG_TOKEN as any);
        return config.mockAuth;
      },
      deps: [Injector],
    },
    {
      provide: NB_AUTH_TOKEN_INTERCEPTOR_FILTER,
      useValue: (request: HttpRequest<any>): boolean => {
        if (request.responseType === 'json' && request.url.includes('assets/config/config.json')) {
          return true;
        }
        return false;
      },
    },
    { provide: HTTP_INTERCEPTORS, useClass: NbAuthJWTInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(translate: TranslateService) {
    translate.setDefaultLang('en');
    translate.use('en');
  }
}
