import { Inject, Injectable } from '@angular/core';
import { Data } from '@angular/router';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { RoleService } from './role.service';
import { RouterDataService } from './router-data.service';

// tslint:disable-next-line: only-arrow-functions
function match(requiredData: string, roleData: string): boolean {
  return typeof requiredData !== 'undefined' && roleData !== '*' && roleData !== requiredData;
}

export interface AuthorizationData {
  microservice: string;
  operation: string;
  scope: string;
}

@Injectable()
export class RoleMatcherService {
  constructor(
    private readonly roleService: RoleService,
    private readonly routerDataService: RouterDataService,
    @Inject('MOCK_AUTH') private readonly mockAuth: boolean
  ) {}

  public match$(authorizationData: Partial<AuthorizationData>): Observable<boolean> {
    const { microservice, operation, scope } = authorizationData || {};
    return this.roleService.roles$.pipe(
      map(
        (roles: string[]) =>
          this.mockAuth ||
          roles.some((role: string) => {
            const [roleMicroservice, roleOperation, roleScope] = role.split(':');
            if (match(microservice, roleMicroservice)) {
              return false;
            }
            if (match(operation, roleOperation)) {
              return false;
            }
            if (match(scope, roleScope)) {
              return false;
            }
            return true;
          })
      )
    );
  }

  public matchFromRouteData$(authorizationData: Partial<AuthorizationData>): Observable<boolean> {
    const { microservice, operation, scope } = authorizationData || {};
    return this.routerDataService.routerData$().pipe(
      switchMap((data: Data) => {
        if (microservice) {
          data.microservice = microservice;
        }
        if (operation) {
          data.operation = operation;
        }
        if (scope) {
          data.scope = scope;
        }
        return this.match$(data as any);
      })
    );
  }
}
