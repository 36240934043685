import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { NbAuthModule } from '@nebular/auth';
import { NbAlertModule, NbButtonModule, NbCheckboxModule, NbIconModule, NbInputModule } from '@nebular/theme';
import { TranslateModule } from '@ngx-translate/core';
import { AuthRoutingModule } from './auth-routing.module';
import { DisabledByAuthDirective } from './directives/disabled-by-auth.directive';
import { DisabledFormByAuthDirective } from './directives/disabled-form-by-auth.directive';
import { IfByAuthDirective } from './directives/if-by-auth.directive';
import { LoginComponent } from './login/login.component';
import { LogoutComponent } from './logout/logout.component';
import { RoleMatcherService } from './services/role-matcher.service';
import { RoleService } from './services/role.service';
import { RouterDataService } from './services/router-data.service';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    NbAlertModule,
    NbInputModule,
    NbButtonModule,
    NbCheckboxModule,
    AuthRoutingModule,
    NbAuthModule,
    TranslateModule,
    NbIconModule,
  ],
  declarations: [LoginComponent, LogoutComponent, DisabledByAuthDirective, IfByAuthDirective, DisabledFormByAuthDirective],
  exports: [DisabledByAuthDirective, IfByAuthDirective, DisabledFormByAuthDirective],
})
export class AuthModule {
  public static forRoot(): ModuleWithProviders<AuthModule> {
    return {
      ngModule: AuthModule,
      providers: [RoleService, RoleMatcherService, RouterDataService],
    };
  }
}
